//
// screenshot.scss
//


.services-box{
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: $light;
  // agregar un color para el borde
  border: 5px solid $primary;

  .services-icon{
    width: 55px;
    height: 55px;
    line-height: 60px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    i{
     
      font-size: 28px;
      color: $primary;
      
    }
  }
}


.services-detail-box{
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: $white;
  // agregar un color para el borde
  border: 0 solid $light;

  .services-icon{
    width: 55px;
    height: 55px;
    line-height: 60px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    i{
     
      font-size: 28px;
      color: $primary;
      
    }
  }
}



.bg-features{
  border-radius: 0px 0px 350px 0px;
}