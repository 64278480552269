//
// team.scss
//

//
// team.scss
//

$color-red: #a51e1e;

.slick-dots, .slick-next, .slick-prev {
	position: relative;
}

#team .slick-dots li button {
	width: 30px;
	height: 7px;
	background-color: $color-red;
	border-radius: 20px;
	padding: unset;
}

#team .slick-dots li button:before,
.main-slider .slick-dots li button:before {
	content: '';
}

#team .slick-dots li, .main-slider .slick-dots li {
	width: unset;
	height: unset;
}

#team .slick-dots {
	bottom: unset;
	margin-top: 70px;
}

#team li.slick-active button {
	opacity: 0.1;
}

#carouselExampleIndicators .slick-dots .slick-active {
	opacity: 1;
	border: 3px solid $color-red;
	z-index: 3;
}

#carouselExampleIndicators .slick-dots {
	width: auto;
}

.main-slider li button {
	border-radius: 20px;
	-webkit-box-shadow: 0 0 0 0 $color-red;
	box-shadow: 0 0 0 0 $color-red;
	height: 12px;
	width: 12px;
	opacity: 1;
	border: 2px solid $color-red !important;
	background: $color-red;
	padding: 3px;
}

.main-slider li.slick-active button {
	background-color: transparent;
	padding: unset;
}

.main-slider .slick-dots {
	bottom: -70px;
}

.main-slider .slides {
	margin: 0;
	padding: 0;
	list-style: none;
}

.team-box {
	.team-badge {
		padding: 3px 10px;
		position: relative;
		display: inline-block;
	}
}

.team-social-icon {
	i {
		position: absolute;
		display: inline-block;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		font-size: 15px;
		line-height: 34px;
		margin-top: -34px;
		margin-left: 30px;
		background: $primary;
		color: $white;
		text-align: center;
	}
}

.team-social {
	i {
		width: 38px;
		height: 38px;
		display: inline-block;
		line-height: 34px;
		background-color: $light;
		border-radius: 50%;
		text-align: center;
		border: 2px solid #f1eddb;
		font-size: 16px;
		&:hover {
			color: $white;
			background-color: $primary;
			transition: all 0.5s;
		}
	}
}

.owl-theme .owl-controls {
	margin-top: 70px;
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 30px;
	height: 7px;
	background-color: $primary;
}

.espace.title-box.text-center {
    margin-top: 10%;
}
