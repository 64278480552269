//
// Footer.scss
//


.bg-footer{
  position: relative;
  border-radius: 350px 0 0 0;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/footer-img.png);

  /* Ajustar la propiedad background-position para alinear a la derecha */
  background-position: right top;

  /* Asegurarse de que ocupe el 100% de la altura */
  background-size: auto 100%;

  /* Resto de tu estilo aquí */

  .contact-img {
    position: absolute;
    margin-top: -280px;
  }
}

.footer-link{
  a {
      color: $white;
      line-height: 38px;
      font-size: 15px;
      transition: all 0.5s;
      &:hover {
          color: $primary;
        }
    }
}

.brillo:hover {
  cursor: pointer;
  color: yellow;
}



.subscribe {
  input {
    padding: 12px 20px;
    width: 100%;
    font-size: 15px;
    color: $muted;
    border: none;
    outline: none !important;
    padding-right: 75px;
    padding-left: 15px;
    background-color: rgba($primary, 0.1);
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    outline: none !important;
    border-radius: 0px 5px 5px 0px;
    padding: 2px 12px;
    font-size: 25px;
  }
  form {
    position: relative;
    max-width: 400px;
  }
  ::placeholder { 
    color: $muted
  }
}

.footer-img-vertical {
  margin-left: 130%;
}


