  
  //
// components.scss
//

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }

    .bg-soft-#{$name} {
        background-color: rgba(($value), 0.1) !important;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }

    // Icons
    .icon-dual-#{$name} {
        color: $value;
        fill: rgba($value, 0.2);
    }

    .btn-soft-#{$name} {
        background-color: rgba(($value), 0.25) !important;
        color: #{$value} !important;
        &:hover, &:focus, &:active,
        &.active, &.focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            background: darken($value, 4%);
        }
    }
    
    .btn-#{$name} {
        background: #{$value} !important;
        border-color: #{$value} !important;
        color: map-get($text-colors, $name) !important; // Establece el color del texto

       
        &:hover, &:focus, &:active,
        &.active, &.focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            background: darken($value, 4%);
            border-color: darken($value, 4%);
        }
    }

    .btn-outline-#{$name} {
        color: #{$value};
        border-color: #{$value};
        &:hover, &:focus, &:active,
        &.active, &.focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:focus,
        .open > .dropdown-toggle.btn-primary {
            background: $value;
            border-color:$value;
    
        }
    }

    .amarillo {
        background-color: $primary;
        color: $dark;
    }

    .negro {
        background-color: $dark;
        color:$primary;
    }
}




